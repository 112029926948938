body {
  height: 100vh !important;
  margin: 0px !important;
}
.relative {
  position: relative !important;
}
.absolute {
  position: absolute !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.p-20px {
  padding: 20px !important;
}
.padding3-20px {
  padding: 3px 19px !important;
}
.padding3-30px {
  padding: 3px 30px !important;
}
.mt-10px {
  margin-top: 10px !important;
}
.mt-20px {
  margin-top: 20px !important;
}
.mt-8px {
  margin-top: 8px !important;
}

.ml-10px {
  margin-left: 10px !important;
}
.mr-10px {
  margin-right: 10px !important;
}
.linkcolor {
  text-decoration: none !important;
  color: #525f7f !important;
  font-family: "Play", sans-serif !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.custom-tooltip {
  font-family: Arial, sans-serif !important;
  font-size: 12px !important;
  color: red !important;
  /* Additional styles as needed */
}
.error {
  color: red !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}
.width100 {
  width: 100% !important;
}
.search-input {
  min-width: 280px !important;
}
.notification-unread {
  background-color: #d5cbf9 !important;
  padding: 10px 0px !important;
  border-bottom: 1px solid #ddd !important;
  cursor: pointer !important;
  border: 1px solid #ddd !important;
}
.notification-read {
  background-color: #f7f9fb !important;
  padding: 10px 0px !important;
  border: 1px solid #ddd !important;
  border-radius: 8px !important;
  cursor: pointer !important;
}
.badge .MuiBadge-badge {
  background-color: #5932ea !important;
}

::-webkit-scrollbar-thumb {
  background: #5932ea !important;
  width: 12px !important;
  height: 12px !important;
}
::-webkit-scrollbar-thumb:hover {
  background: #ddd !important;
}
::-webkit-scrollbar-track {
  background: transparent !important;
}
::-webkit-scrollbar {
  width: 6px !important;
  height: 5px !important;
}
/* Sidebar Style */
.SideBarIcon {
  font-size: 1.4rem !important;
}
.cursor-point {
  cursor: pointer !important;
}

.primary-color {
  color: #5932ea !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}
.disable-color {
  color: #b0b0b0 !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}
.status-ongoing {
  background-color: #ffb900 !important;
  border-radius: 8px !important;
  color: #fff !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  width: 90px !important;
  padding: 4px 3px !important;
  text-align: center !important;
}

.status-complete {
  background-color: #47cd75 !important;
  border-radius: 8px !important;
  color: #232323 !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  width: 90px !important;
  padding: 4px 3px !important;
  text-align: center !important;
}

.status-n-a {
  background-color: red !important;
  border-radius: 8px !important;
  color: #fff !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  width: 120px !important;
  padding: 4px 3px !important;
  text-align: center !important;
}
.status-complete-text {
  border-radius: 8px !important;
  color: #fff !important;
  background-color: #47cd75 !important;
  padding: 4px 8px !important;
  margin-left: auto !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}
.status-red-text {
  border-radius: 8px !important;
  color: #fff !important;
  background-color: red !important;
  padding: 4px 8px !important;
  margin-left: auto !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}
.status-orange-text {
  border-radius: 8px !important;
  color: #fff !important;
  background-color: orange !important;
  padding: 4px 8px !important;
  margin-left: auto !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}
.tablecell-bg {
  color: #969696 !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}
.tablecrow-cell-bg {
  color: #232323 !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}
.tablecrow-cell-bg-red {
  color: red !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
}
.tablecrow-cell-bg-orange {
  color: orange !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
}
.tablecrow-cell-bg-green {
  color: #47cd75 !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}

.imglogin {
  background: rgba(255, 255, 255, 0.1) !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  border-radius: 0px 6px 6px 0px !important;
}

.breadcrumb {
  position: fixed !important;
  top: 26px !important;
  z-index: 1111 !important;
  cursor: pointer !important;
  margin-left: 80px !important;
}
.login-container {
  background-image: url("../../../public/img/login.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-box {
  width: 500px;
  /* min-height: 600px;
  max-height: 800px; */
  box-sizing: border-box;
  padding: 10px 40px;
  background: linear-gradient(
    138.63deg,
    rgba(217, 217, 217, 0.25) -2.3%,
    rgba(217, 217, 217, 0) 109.72%
  );
  border: 1px solid #ddd;
  backdrop-filter: blur(20px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.TabChangesDevice {
  min-width: 0px !important;
  font-family: "Calibri" !important;
  font-style: normal !important;
  text-transform: capitalize !important ;
  font-weight: 700 !important;
  font-size: 18px !important;
  min-height: 40px !important;
  border-radius: 7px !important;
  padding: 6px 12px !important;
  /* width: 10% !important; */
  color: #b0b0b0 !important;
}

.MuiTabs-indicator {
  background-color: #5932ea !important;
}

.TabChangesDevice.Mui-selected {
  color: #5932ea !important;
  backdrop-filter: blur(10px) !important;
  border-radius: 15px !important;
}
.dialogtitle-bg-red {
  background-color: red !important ;
  padding: 10px 20px !important;
}
.dialog-iconbtn {
  position: absolute !important;
  right: 8px !important;
  top: 8px !important;
}

/* Home component */

/* Order-Details Component */

.overview-item {
  min-height: 40vh !important;
  border-radius: 8px !important;
  border: 1px solid #ddd !important;
}
.table-container {
  border-radius: 8px !important;
  border: 1px solid #ddd !important;
  /* max-height: 550px !; */
}

.step-icon {
  opacity: 0 !important;
  transition: opacity 0.5s ease-in-out !important;
}

.step-icon.active {
  opacity: 1 !important;
  animation: pulse 2s infinite !important; /* Add a pulsing animation */
}
/* Analyics */
.Selectdropstyle {
  width: 100% !important;
  color: #808080 !important;
  font-size: 15px !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 600 !important;
  border-radius: 6px !important;
  border: 1px solid #ddd !important;
}
.Selectdropstyle .MuiOutlinedInput-root,
.MuiInputBase-formControl {
  border-radius: 8px !important;
}
.Selectdropstyle .MuiSelect-select {
  padding: 6px 7px !important;
}
.Selectdropstyle .MuiSelect-select {
  font-size: 16px !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 600 !important;
}

.Selectdropstyle .MuiSelect-nativeInput .MuiSvgIcon-root {
  color: #5a5a5a !important;
  border-radius: 8px !important;
}
.Selectdropstyle .MuiSvgIcon-root,
.MuiSelect-iconOutlined {
  color: #5a5a5a !important;
}
.Selectdropstyle .MuiSelect-select {
  background-color: #f7f8fd !important;
  border-radius: 8px !important;
}

.rangepicker .MuiInputBase-input {
  padding: 8px 6.5px !important;
  font-size: 16px !important;
  border-radius: 6px !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}
.rangepicker {
  border: 1px solid #dddddd !important;
  background-color: rgba(4, 74, 112, 0.15) !important;
  border-radius: 8px !important;
}

.rangepicker .MuiFilledInput-root {
  background-color: #fff !important;
}
.rangepicker .MuiButtonBase-root {
  background-color: #f7f8fd !important;
  border-radius: 0px !important;
  padding: 4px !important;
}

.logs-files-main {
  border: 1px solid #ddd !important;
  border-radius: 8px !important;
  position: relative !important;
  padding: 10px !important;
}

.logs-dwd-icon {
  position: absolute !important;
  top: -2px !important;
  right: 0px !important;
}
.logs-icon-folder {
  height: 100px !important;
  width: 120px !important;
  margin-top: 10px !important;
}
.text-width {
  width: 150px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  cursor: pointer !important;
}
@keyframes pulse {
  0%,
  100% {
    opacity: 1 !important;
  }
  50% {
    opacity: 0.5 !important;
  }
}
